<template>
    <div class="link-gallery-module-container">
        <div 
            v-for="(img, i) in config.images" 
            class="link-gallery-content" 
            :key="'link-img-'+i"
        >
            <a :href="img.link">
                <img v-lazy="getImg(img.img)" alt="">
            </a>
            <inline-edit :link="img.link" element="a" :target="img.text"/>
        </div>
    </div>
</template>

<script>

    import * as imgManager from "@/store/imgManager";
    export default {
        props: ["config"],
        methods:{
            getImg(image){
                let img = imgManager.getImage(image);
                if(img){
                    if(img.path){
                        return img.path;
                    }else{
                        return img.file;
                    }
                }
            }
        },
        mounted(){
            for(let i=0; i < this.config.images.length; i++){
                if(typeof this.config.images[i].text === 'string'){
                    this.config.images[i].text = {value: this.config.images[i].text}
                }
            }
        }
    }
    
</script>